import moment from 'moment';

class SellService {
    statusList = [
        {
          label: "En attente FDR",
          color: "light"
        },
        {
          label: "Dossier incomplet",
          color: "warning"
        },
        {
          label: "En attente pose",
          color: "info"
        },
        {
          label: "En attente paiement",
          color: "primary"
        },
        {
          label: "Encaissée",
          color: "success"
        },
        {
          label: "Annulée",
          color: "danger"
        }
    ]

    sourcesList = [
        "R1",
        "REF",
        "VA"
    ]

    financialSection = {
      price: 0,
      deposit: 0,
      credit: 0,
      monthly: 0,
      monthlyWithIns: 0,
      total: 0,
      duration: '',
      rate: 0,
      taeg: 0,
      months: ''
    }

    getStatusColor(s) {
        var color = '';
        this.statusList.map((status) => {
            if (status.label == s) {
                color = status.color;
            }
        });
        return color;
    }

    getDate(created_date) {
        if (created_date) {
            let arr = created_date.split(' ');
            return arr[0];
          } else {
            return '';
          }
    }

    getNumber(id, created_date, company) {
        // TEMPORARY
        if (!id) {
          id = '?';
        }

        if (created_date) {
            let momentObj = moment(created_date, "DD/MM/YYYY H:i");
            let year = momentObj.format('YYYY');
            let month = momentObj.format('MM');
    
            return year + '-' + month + '-' + company.prefix + '-' + id;
          } else {
            return '';
          }
    }
}

export default new SellService();